.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e2430;
  min-height: 100vh;
  width: 67vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-header p {
  word-wrap: break-word;
  padding: 60px;
  opacity: 0; /* Start with zero opacity */
  animation: fadeInFromLeftDelay 3.0s forwards; /* Apply animation */
}

.App-header h3 {
  font-size: 36px;
    color: #5fe3fa;
    opacity: 0; /* Start with zero opacity */
    animation: fadeInFromLeft 1.5s forwards; /* Apply animation */
}

@keyframes fadeInFromLeft {
  0% {
      opacity: 0; /* Start with zero opacity */
      transform: translateX(-50px); /* Move left by 50px */
  }
  100% {
      opacity: 1; /* End with full opacity */
      transform: translateX(0); /* Move back to initial position (no translation) */
  }
}

@keyframes fadeInFromLeftDelay {
  0% {
      opacity: 0; /* Start with zero opacity */
      transform: translateX(-50px); /* Move left by 50px */
  }
  50%{
    opacity: 0; /* Start with zero opacity */
    transform: translateX(-50px); /* Move left by 50px */
  }
  100% {
      opacity: 1; /* End with full opacity */
      transform: translateX(0); /* Move back to initial position (no translation) */
  }
}

.Side-bar-test {
  background-color: #000a1d;
  display: flex;
  flex-direction: column;
  width: 33vw;
  align-items: center;
  justify-content: center;
  color: white;
}

.Horizontal-test {
  display: flex;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

.code-block-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 250px;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #ccc;
  font-size: 14px; /* Adjust the font size as needed */
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
