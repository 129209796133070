.animated-link {
    display: inline-block;
    position: relative;
    color: #ffffff; /* Set your desired text color */
    text-decoration: none;
    font-size: 16px; /* Set your desired font size */
    margin: .5rem;
  }
  
.animated-link::before {
content: '';
position: absolute;
width: 100%;
height: 2px; /* Set your desired underline height */
bottom: 0;
left: 0;
background-color: #61dafb; /* Set your desired underline color */
transform-origin: bottom right;
transform: scaleX(0);
transition: transform 0.3s ease-out;
}

.animated-link:hover::before {
transform-origin: bottom left;
transform: scaleX(1);
}