.social-links {
    display: flex;
    min-width: 100px;
    justify-content: space-between;
    width: 50px; /* Adjust as needed */
  }
  
  .social-links a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #747474;
    font-weight: bold;
  }
  
  .social-links a svg {
    margin-right: 10px;
  }