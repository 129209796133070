.rounded-image {
    width: 450px;
    height: 450px;
    object-fit: cover; /* Maintain aspect ratio and cover container */
    transform: translate(-31%,-15%);
}

.Pic-container {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}